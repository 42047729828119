import dev from './dev.js';
import qa from './qa.js';
import prod from './prod.js';

const pkgConfig = require('../../package.json');

const backend = pkgConfig.config.backend;

let config = null;

switch (backend) {
  case 'qa':
    config = qa;
    break;
  case 'prod':
    config = prod;
    break;
  default:
    config = dev;
}

export default config;
