import config from './cfg';

export async function oauthComplete(queryParams, api_key) {
    const endpoint = `${config.api_url}/tap/auth?${queryParams}`

    return await fetch(endpoint, {
        headers: { 'x-api-key': api_key, 'Content-Type': 'application/json' },
    });
}

export async function oauthCompleteV2(queryParams, api_key) {
    const endpoint = `${config.api_url}/v2/entity/auth?${queryParams}`

    return await fetch(endpoint, {
        headers: { 'x-api-key': api_key, 'Content-Type': 'application/json' },
    });
}
