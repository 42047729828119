import React, { Component } from 'react';
import swal from 'sweetalert';
import TextTransition, { presets } from 'react-text-transition';
import { Helmet } from "react-helmet";
import { Buffer } from 'buffer';

import { oauthComplete, oauthCompleteV2 } from './api';
import './App.css';

const queryString = require('querystring');

async function performCallback(params, url, api_url, api_key) {
  queryString.stringify({ q: params.soqlQuery });

  for (const [key, value] of url.searchParams) {
    if (key !== 'state') {
      params[key] = value;
    }
  }

  try {
    if (params['error']) {
      throw new Error(`${params['error']} - ${params['error_description']}`);
    }

    const result = await (
      params.flowVersion === 2
        ? oauthCompleteV2(queryString.stringify(params), api_key)
        : oauthComplete(queryString.stringify(params), api_key)
    );
    console.log('OAUTH response', result);
    window.close();
  } catch (err) {
    await swal('Error', `Oops, we had some trouble linking up. ${err}`, 'error').then(() => {
      setTimeout(() => window.close(), 55000);
    });
  }
}

const TEXTS = ['Connecting data', 'Loading fields', 'Transferring state'];

export default class Callback extends Component {
  state = {
    index: 0,
    domain: 'hotglue.xyz',
  };

  componentDidMount() {
    const self = this;
    const url = new URL(window.location.href);
    let state = url.searchParams.get('state');

    if (state) {
      // Check if params are base64
      if (Buffer.from(state, 'base64').toString('base64') == state) {
        // If they are, convert to utf-8 first
        state = Buffer.from(state, 'base64').toString('utf-8')
      }

      let params = JSON.parse(state.replace(/&quot;/g, '"'));

      if (params) {
        let { api_url, api_key, domain, domainName, favicon, bgColor, hideText = false } = params;
        self.setState({ domain, domainName, favicon, bgColor, hideText });
        performCallback(params, url, api_url, api_key);
      }
    }

    // Animate loading text
    setInterval(() => self.setState({ index: self.state.index + 1 }), 5000);
  }

  render() {
    const { index, domain, domainName, favicon, bgColor, hideText } = this.state;

    return (
      <div>

        <Helmet>
          {domainName && <title>{domainName}</title>}
          {favicon
            ? <link rel="icon" type="image/png" href={favicon} />
            : <link rel="icon" href="https://hotglue.xyz/favicon.ico" />
          }
        </Helmet>
        <section className="root-container" style={{ backgroundColor: bgColor }}>
          <div className="container">
            <div>
              {domain === 'hotglue.xyz' ? (
                <img src={require('./assets/hotglue_logo.gif')} alt="hotglue logo" style={{ maxHeight: '200px' }} />
              ) : (
                <img src={domain} alt="company logo" style={{ maxWidth: '200px' }} />
              )}
            </div>
            {!hideText && <div className="my-5">
              <div className="text-container">
                <TextTransition
                  text={TEXTS[index % TEXTS.length]}
                  springConfig={presets.wobbly}
                  style={{ textAlign: 'center', fontSize: '25px' }}
                />
              </div>
            </div>}
          </div>
        </section>
      </div>
    );
  }
}
